import { Navigate, Outlet } from 'react-router-dom';
import { Paths } from '@Src/constants';
import { hasValidCookies } from '@Src/hooks/useCookies';
import { useEffect, useState } from 'react';

function AuthenticatedRoute() {
  const [validCookies, setValidCookies] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setValidCookies(hasValidCookies());
  }, []);

  return validCookies === undefined
    ? <>Loading...</>
    : validCookies
      ? <Outlet />
      : <Navigate replace to={Paths.login} />;
}

export default AuthenticatedRoute;
