import { createApi } from '@reduxjs/toolkit/query/react';
import { checkCookiesBaseQuery } from '@Api/utils-slice';
import { ApiTags } from '@Src/constants';

export const serviceApi = createApi({
  reducerPath: 'serviceApi',
  baseQuery: checkCookiesBaseQuery,
  tagTypes: Object.values(ApiTags),
  endpoints: () => ({}),
});
