enum ApiTags {
  agenti = 'AGENTI',
  clienti = 'CLIENTI',
  login = 'LOGIN',
  manualOfflineMode = 'MANUAL_OFFLINE_MODE',
  me = 'ME',
  scadenziario = 'SCADENZIARIO',
  statoOrdini = 'STATO_ORDINI'
}

const isOffline = () => !!localStorage.getItem('manual-offline');

enum LSKeys {
  manualOffline = 'manual-offline',
  me = 'me',
}

enum Paths {
  agenti = '/agenti',
  clienti = '/clienti',
  home = '/',
  login = '/login',
  scadenziario = '/scadenziario',
  statoOrdini = '/stato-ordini',
}

enum FixedCacheKeys {
  logoutOnGetMeError = 'logout-on-getme-error',
}

enum FeatureFlags {}

export {
  ApiTags,
  Paths,
  FeatureFlags,
  FixedCacheKeys,
  isOffline,
  LSKeys,
};
